import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'acorn-checkbox',
  templateUrl: 'checkbox.component.html',
  styleUrls: ['checkbox.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatCheckboxModule],
})
export class CheckboxComponent {
  @Input() checked = false;
  @Input() label!: string;
  @Input() isFileNotes : boolean = false;
  @Input() isAppComplete : boolean = false;

  @Output() changed = new EventEmitter<boolean>();
}
