<mat-checkbox
  class="acorn-checkbox"
  color="primary"
  [labelPosition]="'before'"
  [checked]="checked"
  [disableRipple]="true"
  [disabled]="isFileNotes || isAppComplete"
  (change)="changed.emit($event.checked)"
>
  {{ label }}
</mat-checkbox>
